import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CCTVCamera = () => {
  return (
    <React.StrictMode>
        <section className="service_background">
        <div>
          <div>
            <img src={"/assets/img/Services-bg.jpg"} alt="" />
          </div>
          <div className="container">
            <div className="service_contant">
              <h1>CCTV Camera For Exam</h1>
              <p>Home / CCTV Camera For Exam</p>
            </div>
            <div className="overlay_service"></div>
          </div>
        </div>
      </section>
      <section className="Service_cards">
        <div className="Service_heading">
          <h1>CCTV Cameras For Exam</h1>
        </div>
        <div className="service_cards">
          <div className="card_page">
            <h3>CCTV Cameras For Exam</h3>
            <p>Offline and online examinations can lead to various malpractices, like cheating, personification, tampering with question papers or answer scripts, and more. Are you looking for an effective option to prevent any malpractice? Contact us now. With our high-end CCTV cameras, it will be much easier for you to easily detect any inappropriate activities and prevent malpractice. </p>
          </div>
          <div className="card_page">
            <h3>Live Video</h3>
            <p>Our CCTV cameras can be used for live video monitoring of your exam center to control any unfair event.</p>
          </div>
          <div className="card_page">
            <h3>Lower the Cost</h3>
            <p>Deploy our solution, and you can lower the exam squad officers' costs to enjoy proactive monitoring.</p>
            <p>Our CCTV camera solution will help our clients to conduct fair examinations while following a transparent approach, and they can also make sure that best practices are followed by the invigilators and students. Your examination center needs to be monitored 24x7, and we can help you with that by offering professional CCTV surveillance. </p>
          </div>
          <div className="card_page">
            <h3>Will daylight damage the LEDs of an infrared camera?</h3>
            <p>No, not at all. Our high-end CCTV cameras are designed to work properly for both lighted and low-light use. So daylight won't damage them. You can install them at any place you want. </p>
          </div>
          <div className="card_page">
            <h3>How does a security camera in an exam center help?</h3>
            <p>We offer superior-quality CCTV cameras that will enable the exam center's live monitoring for transparency. Besides, advanced AI-led security can act as a critical means for the fair conduct of exams. </p>
          </div>
          <div className="card_page">
            <h3>How do I install the CCTV cameras?</h3>
            <p>You don't have to worry about the installation, as we will handle that. Our professionals ensure the fault-free installation of CCTV and make sure that the cameras can cover the entire premises. </p>
          </div>
        </div>
      </section>
    </React.StrictMode>
  );
};

export default CCTVCamera;
