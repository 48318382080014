
import React, { useEffect, useState } from 'react';
import BrandLogoSlider from "../component/SliderComponent/BrandLogoSlider"
import { Link } from 'react-router-dom';


const Home = () => {

  const [services, setServices] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL_LIVE;

  useEffect(() => {
    fetchMenu();
  }, []);

  const fetchMenu = async () => {
    try {
      // const response = await fetch(`${apiURL}servicesSubservicesList/`);
      // const data = await response.json();

      // setServices(data.data[1].subservices);
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  }
  return (
    <React.StrictMode>

      <section className="banner d-flex align-items-center ">
        <div className='container'>
          <div className="px-3 text-white">
            <h1 data-aos="fade-up">Best Examination Management Services</h1>
            <div className="subtitle text-uppercase" data-aos="fade-up">We are committed to offering the industry's best solutions to facilitate efficient examination management.</div>
          </div>
        </div>
      </section>

      <div className='container'>
        <section id="about" className="About_amin_class py-5 my-lg-5" >
          <div className="px-3 about_us_contant">
            <h1 className="title-section pb-2 mb-0">About Us</h1>
            <p className="text-start">We offer the best exam management or administration solutions to help testing bodies and education institutions effectively organize exams and accurately evaluate the results. It covers end-to-end examination management service-from application processing to result-in processing. We have been offering 360-degree solutions since 2015. Our CEO has more than 18 years of experience in the field of journalism, and he is an electronic media expert with expertise in filmmaking, videography, media production, and more. </p>
            <div className='about-check-list d-flex'>
              <div data-aos="fade-up" data-aos-delay="200" className="banner_two bg-cover aos-init aos-animate"></div>
              <ul className='About_us_points'>
                <li>Faster Result Process</li>
                <li>Better Candidate Authentication</li>
                <li>Enhanced Security</li>
              </ul>
            </div>
          </div>
          <div className="col-xl-6 col-md-10 col-lg-6 pl-xl-5 col-12 mt-5 mt-xl-0">
            <div className="about-thum">
              <div className="item top-image text-right aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <img src={'/assets/img/About_us.jpg'} alt="..." />
              </div>
              <div className="item bottom-image text-end aos-init aos-animate about_us_img_2" data-aos="fade-up" data-aos-delay="150">
                <img src={'/assets/img/about-us-1.jpg'} alt="..." />
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <section className="bg-light py-5">
        <div className="px-3">
          <h1 className="title-section pb-0 mb-0 text-center">Activities</h1>
          <p className="text-center pb-30 font20">Stories about people, research, and innovation across the Farm</p>
          <div className="px-3">
            <div className="row">
              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Drama</h5>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Photgraphy</h5>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Music/Dance/Singing</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Literature</h5>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Programmers</h5>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Fashion & Lifestyle</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ paddingTop: "20px" }}>
              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Short Film Making</h5>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-center">Sports</h5>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section> */}
      <section className="studentsay pt-5">
        <div className='container'>
          <div className="px-3">

            <div className="row">
              <div className="col-lg-9 m-auto position-static" style={{ zIndex: '1' }}>
                <blockquote className="blockquote text-center">
                  <div className="testimg"><img src="assets/img/Courses3.jpg" /></div>
                  <p className="mb-0 text-white pt-4">Jammer Installation Management System is a unique platform through which jammer installation process and manpower management is done. Real time monitoring of entire duty operation is done through high-tech solution, be it attendance of staff on duty or real time monitoring of service certificates and inventory management, everything is possible with the help of JIMS platform. </p>
                </blockquote>

              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='container'>
        <section className="py-5 biometrice_verification">
          <div className='biometrice_verification_display'>
            <div className='biometrice_verification_contant'>
              <div className='Biometric_img'></div>
              <h1 className="title-section pb-0 mb-0">CCTV Camera For Exam</h1>
              <p className="pb-3 font20">High-end CCTV cameras equipped with ML and AI capabilities to monitor inappropriate activities. With our solutions, it will be easier to prevent malpractice.</p>
              <p className="pb-3 font20">Offline and online examinations can lead to various malpractices, like cheating, personification, tampering with question papers or answer scripts, and more. Are you looking for an effective option to prevent any malpractice? Contact us now. With our high-end CCTV cameras, it will be much easier for you to easily detect any inappropriate activities and prevent malpractice</p>
            </div>
            <div>
              <img src={"/assets/img/CCTV-Camera.jpg"} alt="" />
            </div>
          </div>
        </section>
      </div>
      <section className="py-5 Other_Info">
        <div className='container'>
          <div className="px-3">
            <div className="row">
              <div className="Other_Info_heading col-lg-9 m-auto ">
                <h1 className="title-section pb-0 mb-0 text-center">Other Information</h1>
                <p className="text-center pb-3 font20">Building a vibrant community of creative and accomplished people from around the world</p>
              </div>
            </div>
            <div className='row'>
              <div className="col-md-5 col-xl-3 col-12">
                <div className="single-service-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                  <div className="icon bg-cover Image_url_1">
                  </div>
                  <div className="content-visible">
                    <h4><a href="#">Biometric Verification</a></h4>
                    <p>Accurate IRIS, Face and Fingerprint...</p>
                  </div>
                  <div className="content-overlay">
                    <h4><a href="#">Biometric Verification</a></h4>
                    <p>High-end CCTV cameras equipped with ML and AI capabilities to monitor inappropriate activities. With our solutions, it will be easier to prevent malpractice.</p>
                    {/* <a href="#" class="read-link">learn more <i class="fas fa-arrow-right"></i></a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-xl-3 col-12">
                <div className="single-service-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                  <div className="icon bg-cover Image_url_2">
                  </div>
                  <div className="content-visible">
                    <h4><a href="#">OMR Sheets</a></h4>
                    <p>Scanning and uploading OMR Sheets...</p>
                  </div>
                  <div className="content-overlay">
                    <h4><a href="#">OMR Sheets</a></h4>
                    <p>Scanning and uploading OMR Sheets on highly secured cloud servers. We can also design OMR sheets based on our client's requirements.</p>
                    {/* <a href="#" class="read-link">learn more <i class="fas fa-arrow-right"></i></a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-xl-3 col-12">
                <div className="single-service-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                  <div className="icon bg-cover Image_url_3">
                  </div>
                  <div className="content-visible">
                    <h4><a href="#">Security - security guards</a></h4>
                    <p>Our online and offline exam security...</p>
                  </div>
                  <div className="content-overlay">
                    <h4><a href="#">Security - security guards</a></h4>
                    <p>Our online and offline exam security solutions can help the education sector at the best pricing and strengthen its exam monitoring and evaluation process.</p>
                    {/* <a href="#" class="read-link">learn more <i class="fas fa-arrow-right"></i></a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-xl-3 col-12">
                <div className="single-service-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                  <div className="icon bg-cover Image_url_4">
                  </div>
                  <div className="content-visible">
                    <h4><a href="#">Videography</a></h4>
                    <p>Exam administration can view the...</p>
                  </div>
                  <div className="content-overlay">
                    <h4><a href="#">Videography</a></h4>
                    <p>Exam administration can view the log of the videos taken during the exam, and it will help them to detect if candidates appear for the examination without any help.</p>
                    {/* <a href="#" class="read-link">learn more <i class="fas fa-arrow-right"></i></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-5 Surveillance'>

        <div>
          <h1 className="title-section pb-0 mb-0">Surveillance</h1>
          <p className="pb-3 font20">We provide our clients with technology-driven examination surveillance solutions that will secure all aspects of their examinations. We facilitate live monitoring and surveillance.</p>
        </div>
        <div>
          <img src={"/assets/img/Surveillance-img.jpg"} alt="" />
        </div>
      </section>

      {/* <section className="py-5">
        <BrandLogoSlider />
      </section> */}

      {/* <div className="social">
        <div className="px-3">
          <ul className="d-flex justify-content-center">
            <li><a title="Facebook" className="xsu-link" data-ga-label="Facebook" href="https://www.facebook.com/NarainaGroupOfInstitutions"><span className="fa fa-facebook-square" aria-hidden="true"></span></a></li>
            <li><a title="Twitter" className="xsu-link" data-ga-label="Twitter" href="https://twitter.com/i/flow/login?redirect_after_login=%2FNarainaGroup"><span className="fa fa-twitter" aria-hidden="true"></span></a></li>
            <li><a title="Instagram" className="xsu-link" data-ga-label="Instagram" href="#"><span className="fa fa-instagram" aria-hidden="true"></span></a></li>
            <li><a title="LinkedIn" className="xsu-link" data-ga-label="LinkedIn" href="https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fgroups%3Fgid%3D3045840&trk=login_reg_redirect"><span className="fa fa-linkedin" aria-hidden="true"></span></a></li>
            <li><a title="YouTube" className="xsu-link" data-ga-label="YouTube" href="#"><span className="fa fa-youtube-play" aria-hidden="true"></span></a></li>

          </ul></div>
      </div> */}



    </React.StrictMode >
  );
};

export default Home;
