import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";

function Header(props) {
  
  
  return (
    <React.StrictMode>
      <header class="header-wrap header-1">
        <div class="container">
          <div class="justify_contant align-items-center">
            <div class="col-6 col-sm-5 col-xl-2">
              <div class="logo">
                <a href="/">
                  <img src="assets/img/logo.jpeg" alt="logo" />
                </a>
              </div>
            </div>
            <div class="col-xl-7 d-none d-lg-block">
              <div class="main-menu">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/service">Services</a>
                  </li>
                  <li>
                    <a href="/CCTV-Camera">CCTV Camera For Exam</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='responsive_icon'>
              <button>
                <i><GiHamburgerMenu /></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.StrictMode >
  );
}

export default Header;
