import React, { useEffect, useState } from 'react'
import './Dancegallary.css'
import { BsArrowRightCircle,BsArrowLeftCircle } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";


function Dancegallary(props) {
  const { id } = props.match.params;
  const [subServices, setSubServices] = useState([]);
  const [nextimage, setNextImage] = useState(0);
  const [Imgpopup, setImgpopup] = useState(false);
  const [Imgpath, setImgpath] = useState();
  const apiURL = process.env.REACT_APP_API_URL_LIVE;
  const homeURL = process.env.REACT_APP_API_URL_LIVE_HOME;
  const [Iconhide , setIconhide] = useState(true)

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await fetch(`${apiURL}/subservicesListById/${id}`);
        const data = await response.json();
        setSubServices(data.data);
      } catch (error) {
        console.error('Error fetching subservices galry data:', error);
      }
    }

    fetchMenu();
  }, [id]);

  function imgurlsend(e, indx) {
    setImgpath(e.target.src);
    setImgpopup(true);
    console.log(e.target.src + " aaya " + indx);
    console.log(slider_images);
  };

function nextImage() {
  if (slider_images.length > nextimage + 1) {
    let nextindex = nextimage +1;
    setNextImage(nextindex);
    setImgpath(homeURL + subServices.slider_images[nextindex].imageUrl);
  } else {
    console.log("Reached the last image.");
    
  }
}

  function previsImage() {
    if (nextimage >=0) {
        setNextImage(nextimage - 1);
      setImgpath(homeURL + subServices.slider_images[nextimage].imageUrl);
    }
  }

  var slider_images = '';
  if (subServices.slider_images != '') {
    slider_images = subServices.slider_images;
  }

  return (
    <section className='pt-5 mt-5 mb-5 ddd'>
      <div className='dance_gallary_box'>
        <div className='dance_gallary_wrapper '>
          {
            (slider_images != undefined && slider_images != '') ?
              slider_images.map((dgallaryimg, indx) => (
                <div className='imggallarybox  p-2' key={indx}>
                  <div className='imgbox_img'>
                    <img
                      className='img_tag'
                      onClick={(e) => imgurlsend(e, indx)}
                      src={homeURL + dgallaryimg.imageUrl}
                      alt={dgallaryimg.title}
                    />
                  </div>
                  <div className='img_text px-2'>
                    {dgallaryimg.title && <h3>{dgallaryimg.title}</h3>}
                    {dgallaryimg.subtitle && <h5>{dgallaryimg.subtitle}</h5>}
                    {dgallaryimg.description && <p style={{ width: '100%', lineHeight: '20px' }}>{dgallaryimg.description}</p>}
                  </div>
                </div>
              ))
              : null
          }
        </div>
      </div>

      {Imgpopup && <>
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000',
            opacity: '1',
            zIndex: '10000000'
          }}
        >
          <div
            onClick={() => setImgpopup(false)}
            style={{
              position: 'fixed',
              top: '11%',
              right: '10%',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: '1',
              cursor: 'pointer',
              color: '#fff',
              fontSize: '50px',
              zIndex: '10000000000'
            }}
          >
            <IoIosCloseCircleOutline />
          </div>
          <div
            onClick={previsImage}
            style={{
              position: 'fixed',
              top: '0',
              left: '5%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#000',
              color: '#fff',
              cursor: 'pointer',
              fontSize: '50px',
              zIndex: '1000000000'
            }}
          >
            
            <i className={nextimage < 0 && 'errow_hide'}>

                <BsArrowLeftCircle />
            </i>
            
          </div>
          <div
            onClick={nextImage}
            style={{
              position: 'fixed',
              top: '0',
              right: '5%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#000',
              color: '#fff',
              cursor: 'pointer',
              fontSize: '50px',
              zIndex: '1000000000'
            }}
          >
            {/* <i className={  'errow_hide'}> */}

            <BsArrowRightCircle />
            {/* </i> */}
            
            
          </div>
          <img
            className='p-5 my-5'
            style={{ width: '80%', height: '80%', backgroundPosition: 'center', backgroundSize: 'cover' }}
            src={Imgpath}
            alt='Image not found'
          />
        </div>
      </>}
    </section>
  );
}

export default Dancegallary;
