import React from "react";

const Service = () => {
  return (
    <React.StrictMode>
      <section className="service_background">
        <div>
          <div>
            <img src={"/assets/img/Services-bg.jpg"} alt="" />
          </div>
          <div className="container">
            <div className="service_contant">
              <h1>Services</h1>
              <p>Home / Services / Services</p>
            </div>
            <div className="overlay_service"></div>
          </div>
        </div>
      </section>
      <section className="Service_cards">
        <div className="Service_heading">
          <h1>Services</h1>
        </div>
        <div className="service_cards">
          <div className="card_page">
            <h3>Examination Management Solutions</h3>
            <p>We bring you end-to-end services for securing as well as simplifying the examinations at scale. We understand that effective and efficient exam management is important to establish better academic achievement as well as competency. The traditional examination management solutions involve hours of coordination and preparation. On the other hand, the concerned authorities have to properly track every student's hall ticket details, registration number, identity verification, etc., manually. However, our solutions will eliminate all such issues.</p>
          </div>
          <div className="card_page">
            <h3>Smooth Management</h3>
            <p>Automation of exam cycle, centralized data management along with security guard allocation.</p>
          </div>
          <div className="card_page">
            <h3>Cost-effective</h3>
            <p>Low installation costs, low IT dependency and less manpower required to manage exams.
              Our solutions, such as Biometric Verification, CCTV camera surveillance, OM Sheets management etc., will facilitate seamless coordination of different aspects, from registrations to candidate verification, along with result tabulation. Besides, we also offer online proctoring and invigilation features so that you can enjoy a fair examination process.
            </p>
          </div>
          <div className="card_page">
            <h3>How Our Online Candidate Enrollment Can Help?</h3>
            <p>We can design simple-to-use mobile applications or online portals based on our client's unique requirements for an easy candidate enrolment process. Our solutions can effectively streamline the registration as well as the enrollment process for the candidates. Besides, you can also get application forms designed based on your specifications.</p>
          </div>
          <div className="card_page">
            <h3>How Can Biometric Technology Secure Online Exams?</h3>
            <p>During the enrollment through websites, the system will ask for ID details and photo proof. At the examination center, the officials can verify the photo proof and ID and can scan the biometrics of the candidate attached to the ID. We offer face verification, IRIS verification and fingerprint verification optimization services to our clients.</p>
          </div>
          <div className="card_page">
            <h3>How Can We Help Educational Institutions?</h3>
            <p>Our exam management solutions are designed to help schools and other educational institutions to effectively organize exams as well as calculate the results. Our solutions will simplify various tasks such as application processing, on-screen marking, identity verification and more. Besides, our examination solutions also support computer-based and OMR- Optical Mark Recognition sheets.</p>
          </div>
        </div>
      </section>
    </React.StrictMode>
  );
};

export default Service;
