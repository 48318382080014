import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link } from 'react-router-dom';

var d = new Date();
var currYear = d.getFullYear();

function Footer() {
  const [course, setCourse] = useState("");
  const [name, setName] = useState("");
  const [fathername, setFatherName] = useState("");
  const [phone, setPhone] = useState("");
  const [parentphone, setParentPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [isStudent, setisstudent] = useState(false);
  const [services, setServices] = useState([]);


  const [isLoading, setIsLoading] = useState(false);


  const [Imgpopup, setImgpopup] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL_LIVE;

  const WebapiURL = process.env.REACT_APP_API_URL_WebURL;

  const AddSupportForm = async (e) => {
    setIsLoading(true);
    var APIURL = '';
    if (isStudent) {
      APIURL = `${WebapiURL}/staff-support`;
    } else {
      APIURL = `${WebapiURL}/student-support`;
    }

    e.preventDefault();
    // API Call 
    const dataObject = {
      // course,
      name,
      // fathername,
      phone,
      // parentphone,
      email,
      address,
      message,
    };

    await fetch(APIURL, {
      method: "POST",
      body: JSON.stringify(dataObject),

      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      res.json().then((data) => {
        const status = data.status;
        if (status === true) {
          setIsLoading(false);
          setImgpopup(false)
          swal("Success", data.message, "success").then((ok) => {
            if (ok) {
              // window.location.reload();
            }
          });
        } else {
          setIsLoading(true);
          swal("Error", data.message, "error");
        }
      });
    });


  };


  useEffect(() => {
    fetchMenu();
  }, []);

  const fetchMenu = async () => {
    try {
      const response = await fetch(`${apiURL}/servicesSubservicesList/`);
      const data = await response.json();
      setServices(data.data);
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  }

  // console.log("services =>>>>> ", services);



  function imgurlsend(e) {
    setImgpopup(true)
    // console.log(e.target.src)
  };
  return (
    <React.StrictMode>
      <footer class="footer">
        <div class="waves">
          <div class="wave" id="wave1"></div>
          <div class="wave" id="wave2"></div>
          <div class="wave" id="wave3"></div>
          <div class="wave" id="wave4"></div>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <div className="Logo">
            <a href="/">
              <img src="assets/img/logo.jpeg" alt="" />
            </a>
            <p>GROUND FLOOR, SCO NO. KBC11, CHD CITY, SEC-45, KARNAL, Karnal, Haryana, 132001</p>
          </div>
          <div className="d-flex gap-5">
            
            <ul class="menu">
              <li class="menu__item"><a class="menu__link" href="#">Home</a></li>
              <li class="menu__item"><a class="menu__link" href="/service">Services</a></li>
              <li class="menu__item"><a class="menu__link" href="/CCTV-Camera">CCTV Camera For Exam</a></li>

            </ul>
            <div className="conatct_us">
              <h3>Contact Us</h3>
              <p><strong>Email:</strong> <a href="mailto:Connect@sssgsolutions.in">Connect@sssgsolutions.in</a></p>
              <p><strong>Number:</strong> <a href="tel:+91 9654971133">+91 96549 71133</a></p>
            </div>
          </div>
        </div>

      </footer>
      <div className="p-3 text-center">
        <p className="mb-0">&copy; COPYRIGHT 2024 <a href="#" className=""><strong>SSSG Solutions</strong></a> - ALL RIGHTS RESERVED.</p>
      </div>
    </React.StrictMode>
  );
}

export default Footer;
