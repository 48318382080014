import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

// import Component
import Header from "./component/Header";
import Footer from "./component/Footer";
import ScrollToTop from "./component/ScrollToTop";
import Service from "./Pages/Services"

// import Pages
import Home from "./Pages/Home";
import CCTVCamera from "./Pages/CctvCamera";

import AOS from "aos";
import "aos/dist/aos.css";
import Dancegallary from "./component/Gallaryproject/DanceGallary/Dancegallary";


function App() {
  const apiURL = process.env.REACT_APP_API_URL_LIVE;


  let history = createBrowserHistory();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <React.StrictMode>
      <Router>
        {/* <Route exact path="/" component={Mantinance} /> */}
      </Router>
      <Router>
        <Header apiURL={apiURL} />
        <ScrollToTop>
          <Switch>
            <Route exact path="/"  component={Home } />
            <Route path="/service" component={Service}/>
            <Route exact path="/CCTV-Camera" component={CCTVCamera} />
            <Route exact path="/events-galary/:id" component={Dancegallary} />

          </Switch>
        </ScrollToTop>
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App;
